import "./App.css";
import Navbar from "./components/Navbar";
import About from "./pages/About";
import Buy from "./pages/Buy";
import FAQ from "./pages/FAQ";
import MainPage from "./pages/MainPage";

function App() {
  return (
    <div className="App">
      <Navbar />
      <MainPage />
      <About />
      <Buy />
      <FAQ />
    </div>
  );
}

export default App;
