import GuyImage from "../images/guy.png";
import { Disclosure } from "@headlessui/react";

export default function FAQ() {
  return (
    <div className="flex h-fit min-h-screen w-screen bg-black">
      <div className="flex flex-col mx-4 mb-10 sm:mb-0 w-full sm:flex-row sm:gap-8 sm:mx-24 sm:justify-between">
        <div className="mt-10">
          <div className="flex md:flex-col md:justify-between md:h-full">
            <div className="text-white uppercase text-left font-normal mb-10 sm:mb-0 text-[40px] sm:text-[70px] w-[100px] sm:w-[200px] leading-[1.1]">
              any questions left?
            </div>

            <img
              src={GuyImage}
              alt="guy"
              className=" hidden md:block md:transform md:origin-bottom md:scale-125"
            />
          </div>
        </div>

        <div className="flex flex-col justify-center gap-3 z-50">
          <Disclosure>
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-[37px] bg-white py-4 px-4 sm:py-6 text-left text-[22px] font-medium uppercase">
                  <span className="max-w-[75%]">who is adun?</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    class="w-10 h-10 bg-[#19334A] p-1 rounded-2xl"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  If you're unhappy with your purchase for any reason, email us
                  within 90 days and we'll refund you in full, no questions
                  asked.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-[37px] bg-white px-4 py-6 text-left text-[22px] font-medium uppercase">
                  <span className="max-w-[75%]">what chain is adun on?</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    class="w-10 h-10 bg-[#19334A] p-1 rounded-2xl"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  No.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-[37px] bg-white px-4 py-6 text-left text-[22px] font-medium uppercase">
                  <span className="max-w-[75%]">how can i buy $adun?</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    class="w-10 h-10 bg-[#19334A] p-1 rounded-2xl"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  No.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-[37px] bg-white px-4 py-6 text-left text-[22px] font-medium uppercase">
                  <span className="max-w-[75%] sm:max-w-[85%]">
                    what is the prediction of $adun price in the future?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    class="w-10 h-10 bg-[#19334A] p-1 rounded-2xl"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  No.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          <Disclosure as="div" className="mt-2">
            {({ open }) => (
              <>
                <Disclosure.Button className="flex w-full items-center justify-between rounded-[37px] bg-white px-4 py-6 text-left text-[22px] font-medium uppercase">
                  <span className="max-w-[75%]">
                    why does $adun look like a returd?
                  </span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                    class="w-10 h-10 bg-[#19334A] p-1 rounded-2xl"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M12 3.75a.75.75 0 0 1 .75.75v6.75h6.75a.75.75 0 0 1 0 1.5h-6.75v6.75a.75.75 0 0 1-1.5 0v-6.75H4.5a.75.75 0 0 1 0-1.5h6.75V4.5a.75.75 0 0 1 .75-.75Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </Disclosure.Button>
                <Disclosure.Panel className="px-4 pb-2 pt-4 text-sm text-gray-500">
                  No.
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
        </div>
      </div>
    </div>
  );
}
