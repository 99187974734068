import GuyImage from "../images/guy.png";
import React, { useEffect, useState } from "react";

export default function About() {
  const address = "TBA";
  const BUTTON_COPY_TEXT_DEFAULT = "Copy";
  const BUTTON_COPY_TEXT_COPIED = "Copied!";

  const [copyText, setCopyText] = useState(BUTTON_COPY_TEXT_DEFAULT);

  async function copyCurrentURLToClipboard() {
    navigator.clipboard.writeText(address).then(
      function () {
        setCopyText(BUTTON_COPY_TEXT_COPIED);
        setTimeout(() => {
          setCopyText(BUTTON_COPY_TEXT_DEFAULT);
        }, 3000);
      },
      function (err) {
        console.error("Could not copy text: ", err);
      }
    );
  }

  return (
    <>
      <div className=" flex relative h-[80vh] md:min-h-screen w-screen bg-[#38DC77]">
        <div className="flex sm:gap-8 sm:justify-between">
          <div className="hidden md:flex h-full md:min-w-[50%]">
            <div className="self-end">
              <img
                src={GuyImage}
                alt="guy"
                className="md:transform md:origin-bottom-left md:scale-[1.75]"
              />
            </div>
          </div>

          <div className="flex justify-center pt-[15%]">
            <div className="bg-white md:z-50 border-[4px] border-black rounded-[40px] shadow-2xl shadow-gray-200  px-8 pt-8 pb-8 max-h-[350px] max-w-[80%]">
              <div className="flex flex-col sm:text-center md:text-left uppercase gap-8">
                <div className="text-3xl md:text-6xl ">about adun</div>
                <div className=" text-xl md:text-2xl md:pb-24">
                  I usdd tu scream un twetch fur uh liven untel ie hud sex wit
                  adru tut. I hade an intervuw wot kanwe butt hee siad I wus gey
                  an juwesh. i lek to pley 2k wut Lebruns sun brunny. kaicanet
                  iz my twen
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="absolute bottom-10 w-full z-50">
          <div className="flex flex-col gap-6 w-full items-center uppercase px-4 md:px-0">
            <div className="flex bg-white w-fit rounded-[20px] px-8 py-2 text-3xl md:text-5xl">
              adun contract
            </div>
            <div className="flex items-center justify-between rounded-full px-8 py-2 uppercase shadow-2xl shadow-gray-200 bg-white w-full md:w-fit md:min-w-[40%]">
              <div className="w-[90%]">{address}</div>
              <button
                className="rounded-full border-2 border-black px-4 py-2 bg-[#38DC77]"
                onClick={copyCurrentURLToClipboard}
              >
                {copyText}
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
