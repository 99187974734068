export default function Buy() {
  return (
    <div className="bg-background w-screen h-fit sm:h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col px-4 sm:px-40">
        <div className="myclass font-justMe text-[50px] lg:text-[200px] uppercase font-bold text-white">
          how to buy
        </div>
        <div className="flex flex-col sm:flex-row w-full sm:gap-24 bg-black/50 text-white font-bold py-4 px-4 sm:py-10 sm:px-20 text-center sm:text-left">
          <div className="flex flex-col gap-8 w-full sm:w-[50%]">
            <div className="flex flex-col gap-2">
              <div className="uppercase text-lg sm:text-[28px]">
                create a wallet
              </div>
              <div className="text-sm sm:text-[20px] sm:leading-relaxed ">
                Download Phantom or your wallet of choice from the app store or
                Google Play for free. Desktop users, download the Google Chrome
                extension by going to Phantom.
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="uppercase text-lg sm:text-[28px]">
                get some sol
              </div>
              <div className="text-sm sm:text-[20px]  sm:leading-relaxed ">
                Have SOL in your wallet to switch to $ADUN. If you don’t have
                any SOL, you can buy directly on Phantom, transfer from another
                wallet, or buy on another exchange and send it to your wallet.
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-8 w-full sm:w-[50%] mt-8 sm:mt-0">
            <div className="flex flex-col gap-2">
              <div className="uppercase text-lg  sm:text-[28px]">
                go to raydium
              </div>
              <div className="text-sm sm:text-[20px]  sm:leading-relaxed ">
                Connect to Raydium. Go to Raydium in google chrome or on the
                browser inside your Phantom app. Connect your wallet. Paste the
                $ADUN token address into Raydium, select $ADUN, and confirm.
                When Phantom prompts you for a wallet signature, sign.
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <div className="uppercase text-lg  sm:text-[28px]">$adun</div>
              <div className="text-sm sm:text-[20px]  sm:leading-relaxed ">
                Switch SOL for $ADUN We have Zero taxes so you don’t need to
                worry about buying with a specific slippage, although you may
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
