export default function MainPage() {
  return (
    <div className="bg-GuyBackground w-screen h-[60vh] sm:h-screen bg-no-repeat bg-cover bg-center">
      <div className="flex flex-col px-4 sm:px-20">
        <div className="myclass font-justMe text-[150px] sm:text-[200px] uppercase font-bold text-white">
          ADUN RROS
        </div>
      </div>
    </div>
  );
}
